.toolbar-logo {
    height: 30px;
    padding: 0 1em;
}

.sign-in-form {
    background: var(--ion-color-primary-contrast);
}

.sign-in-form-container {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sign-in-page .list-md,
.sign-in-page .list-ios {
    background: var(--ion-color-primary-tint);
    height: 100%;
}

.home-page .absence-list .ion-color-secondary,
.home-page .event-list .ion-color-secondary {
    background: #f3b44f;
}

.home-page .absence-list .future,
.home-page .event-list .future {
    display: none;
}

@media only screen and (min-width: 768px) {
    .home-page .absence-list .future,
    .home-page .event-list .future {
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    .home-page .absence-list,
    .home-page .event-list {
        height: calc(100vh - 185px);
        overflow-y: scroll;
    }
}

.home-page ion-datetime {
    margin: -45px 0 0;
}

.contact-info ion-img {
    width: 30%;
    max-width: 200px;
    height: 130px;
    object-fit: cover;
}

ion-menu {
    --width: 100%;
}

.list-header {
    margin-bottom: 18px;
}

.type {
    text-transform: uppercase;
    font-size: 0.9em;
}
.type.syg {
    color: #ff4136;
}
.type.ferie,
.type.fri {
    color: #158cba;
}
.type.ah {
    color: #28b62c;
}
.type.ms,
.type.gt {
    color: #7d26cd;
}
.type.fr,
.type.rejse {
    color: #ffa824;
}
.type.kb {
    color: #ffa824;
}
.type.andet {
    color: #a2a2a2;
}
.type.møde {
    color: #fe642e;
}
.initials {
    text-transform: uppercase;
    color: #e59310;
}

.event-item {
    contain: none;
}

.organisation-segment {
    --background: var(--ion-color-light);
}
